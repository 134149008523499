<template>
  <el-container>
    <el-header>
      <el-row type="flex" justify="center">
        <el-col :span="6" align="right">
          <el-image :src="require('@/assets/gaemons.jpg')" style="height: 50px;margin: 5px auto;" align="left"></el-image>
        </el-col>
        <el-col :lg="16" :xl="12">
          <el-menu :default-active="activeIndex" mode="horizontal" align="center" router>
            <el-menu-item index="/" style="font-size: large" route="/">Home</el-menu-item>
            <el-menu-item index="/LongTarget" style="font-size: large" route="/LongTarget">LongTarget</el-menu-item>
            <el-menu-item index="/LongMan" style="font-size: large" route="/LongMan">LongMan</el-menu-item>
            <el-submenu index="/PopFM">
              <template slot="title"><div style="font-size: large">PopTradeOff</div></template>
              <el-menu-item index="/Introduction" style="font-size: large" route="/PopFMIntro">
                <i class="el-icon-document"></i>
                Introduction
              </el-menu-item>
              <el-menu-item index="/PopFMSumm" style="font-size: large" route="/PopFMSumm">
                <i class="el-icon-s-data"></i>
                Data Summary
              </el-menu-item>
              <el-menu-item index="/PopFMSearch" style="font-size: large" route="/PopFMSearch">
                <i class="el-icon-search"></i>
                Search
              </el-menu-item>
              <el-menu-item index="" style="font-size: large">
                <i class="el-icon-notebook-1"></i>
                <a href="http://www.gaemons.net/wiki/#/poptradeoff" target="_blank">Documentation</a>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="" style="font-size: large"><a href="http://www.gaemons.net/causalcell/causalDiscovery/" target="_blank">Causal Discovery</a></el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </el-header>
    <el-main>
      <keep-alive exclude="LongManList,LongManShow,PopFMList,PopFMShow"><router-view/></keep-alive>
    </el-main>
  </el-container>
</template>

<script>
//import Home from "@/components/Home";

export default {
  name: 'App',
  data() {
    return {
      activeIndex: '/',
    };
  },
  mounted() {
    this.activeIndex = this.$route.path
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style>
@import './assets/font/font.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, SourceCodePro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/components/Home'
import PopFMIntro from "@/components/PopFMIntro"
import PopFMSumm from "@/components/PopFMSumm"
import PopFMSearch from "@/components/PopFMSearch";
import PopFMList from '@/components/PopFMList'
import PopFMShow from "@/components/PopFMShow"
import LongMan from "@/components/LongMan";
import LongTarget from "@/components/LongTarget";
import LongManSearch from "@/components/LongManSearch";
import LongManList from "@/components/LongManList";
import LongManShow from "@/components/LongManShow";
import QueryJob from "@/components/QueryJob";

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: __dirname,
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/PopFMIntro',
            name: 'PopFMIntro',
            component: PopFMIntro
        },
        {
            path: '/PopFMSumm',
            name: 'PopFMSumm',
            component: PopFMSumm
        },
        {
            path: '/PopFMSearch',
            name: 'PopFMSearch',
            component: PopFMSearch
        },
        {
            path: '/PopFMList',
            name: 'PopFMList',
            component: PopFMList
        },
        {
            path: '/PopFMShow',
            name: 'PopFMShow',
            component: PopFMShow
        },
        {
            path: '/LongMan',
            name: 'LongMan',
            component: LongMan
        },
        {
            path: "/LongManSearch",
            name: 'LongManSearch',
            component: LongManSearch
        },
        {
            path: "/LongManList",
            name: 'LongManList',
            component: LongManList
        },
        {
            path: "/LongManShow",
            name: 'LongManShow',
            component: LongManShow
        },
        {
            path: '/LongTarget',
            name: 'LongTarget',
            component: LongTarget
        },
        {
            path: '/QueryJob',
            name: 'QueryJob',
            component: QueryJob
        }
    ]
})

<template>
  <el-row type="flex" justify="center">
    <el-col :lg="16" :xl="12">
      <el-card shadow="always" class="box-card">
        <el-row type="flex">
          <el-col :span="24">
            <div style="font-size: large">
              <p>
                Considerable lncRNA databases have been reported, but no one
                systematically collects orthologues of <i class="el-icon-link" style="color: blue"></i><a
                  href="http://www.gencodegenes.org/">GENCODE</a>-annotated human
                and
                mouse lncRNAs in multiple mammalian clades and species. A systematic collection of orthologous lncRNAs
                in
                multiple species is essential for comparative and
                evolutionary lncRNA analysis and helpful for uncovering whether the functions of an lncRNA show species
                specific variations.</p>
              <p>LongMan is an lncRNA database currently dedicated to systematically collecting GENCODE-annotated
                human
                and mouse lncRNAs and orthologues of GENCODE-annotated human and mouse lncRNAs in multiple
                representative
                mammals, including the marsupial opossum and the monotreme platypus. Currently LongMan has a plain user
                interface,
                but provides strong database search functions comparable to those in popular databases. "Search
                orthologues
                of
                human lncRNA", "Blast", and especially "Search orthologues upon multi-conditions", can flexibly produce
                many
                search results.</p>
              <p>The most important function of lncRNAs is to recruit DNA methylation and histone modification
                enzymes to specific genomic sites to regulate gene expression epigenetically. Thus, the roles of lncRNAs
                vary
                in
                different cellular contexts, depending on where and when they bind to genomic sites. In addition to
                database
                search,
                an important function of LongMan is to support a database-based large-scale or genome-wide lncRNA/DNA
                binding
                prediction. Now, we have integrated LongTarget into LongMan to implement a seamless pipeline to
                implement
                this function. For example, by choosing "Species=human" and "Chr=all" in "Search orthologues upon
                multi-conditions" the database search returns the 13562 GENCODE-annotated human lncRNAs, and from this
                point
                a
                genome-scale
                prediction of the 13562 lncRNAs’ DNA binding sites in a genomic region can be triggered easily.
                <i class="el-icon-more" style="color: blue" @click="isShow=!isShow"></i>
              </p>
              <el-collapse-transition>
              <div v-show="isShow" style="font-size: large">
                <p>
                  Currently the length of a genomic region for a genome-wide prediction is limited to
                  &lt;=20000 bp, this limitation has to be set because our server often runs to multiple jobs in
                  parallel
                  and a genome-wide prediction is time-consuming. But this limitation will be much relaxed soon when our
                  server
                  is upgraded.</p>
                <p>We used the RNA structure prediction program RNAfold and RNA sequence search program Infernal to
                  perform large-scale genome search to obtain orthologues of GENCODE-annotated human lncRNAs in these
                  mammals
                  (see
                  Species and statistics). Such genome search is described in detail in <i class="el-icon-document"
                                                                                           style="color: blue"></i><a
                      href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3831594/">He et al. ANRIL/CDKN2B-AS shows
                    two-stage
                    clade-specific evolution and becomes conserved after transposon insertions in simians. BMC
                    Evolutionary
                    Biology
                    2013, 13:247</a>. Currently, LongMan contains 13562 human lncRNAs, 10481 mouse lncRNAs, the
                  orthologues of
                  the
                  13562 human lncRNAs in the 16 mammals, and soon will include the orthologues of the 10481 mouse
                  lncRNAs in
                  the
                  16 mammals.</p>
                <p>The functions of most human and mouse lncRNAs remain unannotated, for more about the functions, the
                  user is
                  referred to databases, including the UCSC Genome Browser, the Ensembl website, GeneCard database,
                  NONCOE
                  database, and lncRNAdb database (see external links below).</p>
              </div>
              </el-collapse-transition>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin: 20px">
          <el-col :span="8" align="center">
            <el-button type="primary" icon="el-icon-search" @click="handle">Database search</el-button>
          </el-col>
          <el-col :span="8" align="center">
            <el-button type="primary" icon="el-icon-s-data">Species and statistics</el-button>
          </el-col>
          <el-col :span="8" align="center">
            <el-button type="primary" icon="el-icon-download">Data download</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" align="center">
            <el-image :src="ucscImg" style="height: 40px"></el-image>
          </el-col>
          <el-col :span="6" align="center">
            <el-image :src="rnacentralImg" style="height: 40px"></el-image>
          </el-col>
          <el-col :span="6" align="center">
            <el-image :src="genecardsImg" style="height: 40px"></el-image>
          </el-col>
          <el-col :span="6" align="center">
            <el-image :src="noncodeImg" style="height: 40px"></el-image>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" align="center">
            <el-image :src="ensemblImg" style="height: 40px"></el-image>
          </el-col>
          <el-col :span="6" align="center">
            <el-image :src="gencodeImg" style="height: 40px"></el-image>
          </el-col>
          <el-col :span="6" align="center">
            <el-image :src="lncrnadbImg" style="height: 40px"></el-image>
          </el-col>
          <el-col :span="6" align="center">
            <el-image :src="lncipediaImg" style="height: 39px"></el-image>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "LongMan",
  data() {
    return {
      isShow: false,
      ucscImg: require('../assets/ucsc.png'),
      rnacentralImg: require('../assets/rnacentral.png'),
      genecardsImg: require('../assets/genecards.png'),
      noncodeImg: require('../assets/noncode.png'),
      ensemblImg: require('../assets/ensembl.png'),
      gencodeImg: require('../assets/gencode.png'),
      lncrnadbImg: require('../assets/lncrnadb.png'),
      lncipediaImg: require('../assets/lncipedia.png')
    }
  },
  methods: {
    handle() {
      this.$router.push('/LongManSearch')
    }
  }
}
</script>

<style scoped>
.box-card {
  min-width: 100%;
  height: 100%;
  margin-right: 20px;
}

.icon-warp {
  font-size: 150%;
}
</style>

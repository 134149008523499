<template>
  <el-row type="flex" justify="center">
    <el-col :lg="16" :xl="12">
      <el-tabs type="border-card">
        <el-tab-pane label="Simple Search">
          <el-row :gutter="10" type="flex" align="middle">
            <el-col :span="12" align="right">
              <p>Ensembl ID</p>
            </el-col>
            <el-col :span="8">
              <el-input placeholder="ENSG00000130600" size="small" v-model="ensemblid"
                        :disabled="genesymbol!='' || genename!=''"></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" align="middle">
            <el-col :span="12" align="right">
              <p>HGNC gene symbol</p>
            </el-col>
            <el-col :span="8">
              <el-input placeholder="RP4-650F12.2" size="small" v-model="genesymbol"
                        :disabled="ensemblid!='' || genename!=''"></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" align="middle">
            <el-col :span="12" align="right">
              <p>Gene Name (Alias Name)</p>
            </el-col>
            <el-col :span="8">
              <el-input placeholder="HG19" size="small" v-model="genename"
                        :disabled="ensemblid!='' || genesymbol!=''"></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" align="middle">
            <el-col :span="12" align="right">
              <el-button type="primary" icon="el-icon-search" size="small" @click="SimpleSearch">Search</el-button>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Advanced Search">
          <el-row :gutter="10" type="flex" align="middle">
            <el-col :span="4" align="right">
              <p>Species</p>
            </el-col>
            <el-col :span="8">
              <el-select v-model="speciesid" size="small">
                <el-option v-for="item in speciesItems" :key="item.value" :label="item.name"
                           :value="item.value"></el-option>
              </el-select>
            </el-col>
            <el-col :span="4" align="right">
              <p>Chr</p>
            </el-col>
            <el-col :span="8">
              <el-select v-model="chr" size="small">
                <el-option v-for="item in chrItems[speciesid-1]" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" align="middle">
            <el-col :span="4" align="right">
              <p>Start</p>
            </el-col>
            <el-col :span="2">
              <el-select v-model="startOp" size="small">
                <el-option v-for="item in operationItmes" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input size="small" :disabled="startOp==0" v-model="start" type="number"></el-input>
            </el-col>
            <el-col :span="4" align="right">
              <p>End</p>
            </el-col>
            <el-col :span="2">
              <el-select v-model="endOp" size="small">
                <el-option v-for="item in operationItmes" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input size="small" :disabled="endOp==0" v-model="end" type="number"></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" align="middle">
            <el-col :span="4" align="right">
              <p>Strand</p>
            </el-col>
            <el-col :span="8">
              <el-radio-group v-model="strand">
                <el-radio :label="0">Both</el-radio>
                <el-radio :label="1">+</el-radio>
                <el-radio :label="2">-</el-radio>
              </el-radio-group>
            </el-col>
            <el-col :span="4" align="right">
              <p>TE Number</p>
            </el-col>
            <el-col :span="2">
              <el-select v-model="teNumberOp" size="small">
                <el-option v-for="item in operationItmes" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input size="small" :disabled="teNumberOp==0" v-model="teNumber" type="number"></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" align="middle">
            <el-col :span="4" align="right">
              <p>Exon Number</p>
            </el-col>
            <el-col :span="2">
              <el-select v-model="exonNumberOp" size="small">
                <el-option v-for="item in operationItmes" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input size="small" :disabled="exonNumberOp==0" v-model="exonNumber" type="number"></el-input>
            </el-col>
            <el-col :span="4" align="right">
              <p>Total Length</p>
            </el-col>
            <el-col :span="2">
              <el-select v-model="totalLengthOp" size="small">
                <el-option v-for="item in operationItmes" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input size="small" :disabled="totalLengthOp==0" v-model="totalLength" type="number"></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" align="middle">
            <el-col :span="12" align="right">
              <el-button type="primary" icon="el-icon-search" size="small" @click="AdvancedSearch">Search</el-button>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Blast">
          <el-row :gutter="10">
            <el-col :span="4" align="right">
              <p>Enter Sequence</p>
            </el-col>
            <el-col :span="20">
              <el-input type="textarea" rows="8" v-model="sequence" resize="none"
                        onkeyup="value=value.replace(/[^atgcATGC]/g,'')"></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" align="middle">
            <el-col :span="12" align="right">
              <p>
                <el-button type="primary" icon="el-icon-search" size="small" @click="BlastSearch">Search</el-button>
              </p>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" align="middle">
            <el-col :span="4" align="right"></el-col>
            <el-col :span="20">
              <el-table v-show="blastResult.length > 0" :data="blastResult" border
                        :header-cell-style="{'text-align':'center'}">
                <el-table-column prop="seqid" label="Sequence ID" min-width="50%" align="left"></el-table-column>
                <el-table-column prop="bitscore" label="Bit Score" min-width="25%" align="center"></el-table-column>
                <el-table-column prop="evalue" label="E-Value" min-width="25%" align="center"></el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-col>
  </el-row>
</template>

<script>
import axios from "axios";

export default {
  name: "LongManSearch",
  data() {
    return {
      ensemblid: '',
      genesymbol: '',
      genename: '',
      speciesItems: [
        {label: 'Homo sapiens', name: 'Human', value: 1},
        {label: 'Rhesus macaque', name: 'Macaque', value: 2},
        {label: 'Pan troglodytes', name: 'Chimpanzee', value: 3},
        {label: 'Mus musculus', name: 'Mouse', value: 5},
        {label: 'Cavia_porcellus', name: 'Guineapig', value: 6},
        {label: 'Canis familiaris', name: 'Dog', value: 7},
        {label: 'Tupaia belangeri', name: 'Treeshrew', value: 8},
        {label: 'Microcebus murinus', name: 'Mouse lemur', value: 9},
        {label: 'Tarsius syrichta', name: 'Tarsier', value: 10},
        {label: 'Callithrix jacchus', name: 'Marmoset', value: 11},
        {label: 'Rattus norvegicus', name: 'Rat', value: 12},
        {label: 'Oryctolagus cuniculus', name: 'Rabbit', value: 13},
        {label: 'Bos taurus', name: 'Cow', value: 14},
        {label: 'Loxodonta africana', name: 'Elephant', value: 15},
        {label: 'Erinaceus europaeus', name: 'Hedgehog', value: 16},
        {label: 'Monodelphis domestica', name: 'Opossum', value: 17},
        {label: 'Ornithorhynchus anatinus', name: 'Platypus', value: 18}
      ],
      speciesid: 1,
      chrItems: [
        [
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chr22', value: 'chr22'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrY', value: 'chrY'},
        ],
        [
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrUn', value: 'chrUn'},
          {label: 'chrM', value: 'chrM'}
        ],
        [
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2A', value: 'chr2A'},
          {label: 'chr2B', value: 'chr2B'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chr22', value: 'chr22'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrY', value: 'chrY'},
          {label: 'chrUn', value: 'chrUn'},
          {label: 'chrM', value: 'chrM'}
        ],
        [],
        [
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrY', value: 'chrY'},
          {label: 'chrUn', value: 'chrUn'},
          {label: 'chrM', value: 'chrM'}
        ],
        [
          {label: 'chrM', value: 'chrM'},
          {label: 'scaffold', value: 'scaffold'}
        ],
        [
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chr22', value: 'chr22'},
          {label: 'chr23', value: 'chr23'},
          {label: 'chr24', value: 'chr24'},
          {label: 'chr25', value: 'chr25'},
          {label: 'chr26', value: 'chr26'},
          {label: 'chr27', value: 'chr27'},
          {label: 'chr28', value: 'chr28'},
          {label: 'chr29', value: 'chr29'},
          {label: 'chr30', value: 'chr30'},
          {label: 'chr31', value: 'chr31'},
          {label: 'chr32', value: 'chr32'},
          {label: 'chr33', value: 'chr33'},
          {label: 'chr34', value: 'chr34'},
          {label: 'chr35', value: 'chr35'},
          {label: 'chr36', value: 'chr36'},
          {label: 'chr37', value: 'chr37'},
          {label: 'chr38', value: 'chr38'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrUn', value: 'chrUn'},
          {label: 'chrM', value: 'chrM'}
        ],
        [
          {label: 'scaffold', value: 'scaffold'}
        ],
        [
          {label: 'scaffold', value: 'scaffold'}
        ],
        [
          {label: 'scaffold', value: 'scaffold'}
        ],
        [
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chr22', value: 'chr22'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrY', value: 'chrY'},
          {label: 'chrUn', value: 'chrUn'},
        ],
        [
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chrM', value: 'chrM'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrUn', value: 'chrUn'},
        ],
        [
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chrM', value: 'chrM'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrUn', value: 'chrUn'},
        ],
        [
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chr22', value: 'chr22'},
          {label: 'chr23', value: 'chr23'},
          {label: 'chr24', value: 'chr24'},
          {label: 'chr25', value: 'chr25'},
          {label: 'chr26', value: 'chr26'},
          {label: 'chr27', value: 'chr27'},
          {label: 'chr28', value: 'chr28'},
          {label: 'chr29', value: 'chr29'},
          {label: 'chrUn', value: 'chrUn'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrY', value: 'chrY'},
          {label: 'chrM', value: 'chrM'}
        ],
        [
          {label: 'chrM', value: 'chrM'},
          {label: 'scaffold', value: 'scaffold'}
        ],
        [
          {label: 'JH', value: 'JH'},
          {label: 'AMDU', value: 'AMDU'},
          {label: 'chrM', value: 'chrM'}
        ],
        [
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chrM', value: 'chrM'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrUn', value: 'chrUn'},
        ],
        [
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chrX1', value: 'chrX1'},
          {label: 'chrX2', value: 'chrX2'},
          {label: 'chrX3', value: 'chrX3'},
          {label: 'chrX5', value: 'chrX5'},
          {label: 'chrM', value: 'chrM'},
          {label: 'Contig', value: 'Contig'},
          {label: 'Ultra', value: 'Ultra'},
        ],
      ],
      chr: 'chr1',
      operationItmes: [
        {label: 'N', value: 0},
        {label: '=', value: 1},
        {label: '≥', value: 2},
        {label: '≤', value: 3}
      ],
      startOp: 0,
      start: '',
      endOp: 0,
      end: '',
      strand: 0,
      teNumberOp: 0,
      teNumber: '',
      exonNumberOp: 0,
      exonNumber: '',
      totalLengthOp: 0,
      totalLength: '',
      sequence: '',
      blastResult: [],
    }
  },
  methods: {
    SimpleSearch() {
      let query_param = {op: 'simple', speciesid: 1}
      if (this.ensemblid != '') {
        query_param.ensemblid = this.ensemblid
      } else if (this.genesymbol != '') {
        query_param.genesymbol = this.genesymbol
      } else if (this.genename != '') {
        query_param.genename = this.genename
      } else {
        return
      }
      this.$router.push({
        path: '/LongManList',
        query: query_param
      })
    },
    AdvancedSearch() {
      let query_param = {op: 'adv', speciesid: this.speciesid, chr: this.chr}
      if (this.strand != 0)
        query_param.strand = this.strand
      if (this.startOp != 0) {
        query_param.startop = this.startop
        query_param.start = this.start
      }
      if (this.endOp != 0) {
        query_param.endop = this.endop
        query_param.end = this.end
      }
      if (this.teNumberOp != 0) {
        query_param.tenumop = this.teNumberOp
        query_param.tenum = this.teNumber
      }
      if (this.exonNumberOp != 0) {
        query_param.exonnumop = this.exonNumberOp
        query_param.exonnum = this.exonNumber
      }
      if (this.totalLengthOp != 0) {
        query_param.lengthop = this.totalLengthOp
        query_param.length = this.totalLength
      }
      this.$router.push({
        path: '/LongManList',
        query: query_param
      })
    },
    BlastSearch() {
      let that = this
      axios
          .get('/longman/blastsearch/', {params: {seq: that.sequence}})
          .then(function (res) {
            that.blastResult = res.data
          })
    }
  }
}
</script>

<style scoped>
.box-card {
  min-width: 100%;
  height: 100%;
  margin-right: 20px;
}
</style>

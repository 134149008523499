<template>
  <el-row type="flex" justify="center">
    <el-col :xl=16 :lg=20>
      <el-card shadow="always" class="box-card">
        <div id="igv-div"></div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import igv from './igv.esm.min.js'

export default {
  name: "PopFMShow",
  data() {
    return {
      chr: '',
      pos: 0,
      pop: '',
      sup_pop: ''
    }
  },
  mounted() {
    this.chr = this.$route.query.chr
    this.pos = this.$route.query.pos
    this.pop = this.$route.query.pop
    this.sup_pop = this.$route.query.sup_pop
    let chrstr = 'chr' + this.chr
    let chrN = this.chr
    let loc = chrstr + ':' + (parseInt(this.pos) - 4999).toString() + '-' + (parseInt(this.pos) + 5000).toString()
    let igvDiv = document.getElementById("igv-div");
    let data_path = "http://www.gaemons.net/igv"
    let options =
        {
          genome: "hg19",
          locus: loc,
          tracks: [
            {
              "name": "DFscore(" + this.pop + "," + this.sup_pop + ")",
              "type": "wig",
              "format": "bedGraph",
              "url": data_path + "/DFscore_1KGphase3_hg19_sorted_bed/DFscore_" + this.pop + "_" + chrstr + ".bed.gz",
              "indexURL": data_path + "/DFscore_1KGphase3_hg19_sorted_bed/DFscore_" + this.pop + "_" + chrstr + ".bed.gz.tbi",
              "displayMode": "EXPANDED",
              "graphType": "points",
              "roi":
                  [
                    {
                      name: 'Favored SNPs',
                      color: "rgba(255,0,0,0.25)",
                      features: [
                        {
                          "chr": chrstr,
                          "start": this.pos - 1,
                          "end": this.pos
                        },
                      ]
                    },
                  ],
              "color": "#002eff"
            },
            {
              "name": "Hitchhiking-Favored SNP Links(" + this.pop + "," + this.sup_pop + ")",
              "url": data_path + "/HitchSNP_corrCoef_1KGphase3_hg19_sorted_bedpe/" + chrstr + "_" + this.pop + ".bedpe.gz",
              "indexURL": data_path + "/HitchSNP_corrCoef_1KGphase3_hg19_sorted_bedpe/" + chrstr + "_" + this.pop + ".bedpe.gz.tbi",
              "type": "interaction",
              "format": "bedpe",
              "color": "rgb(255,0,255)",
              "height":100,
              //"autoHeight":true,
            },
            {
              "name": "1KG Phase 3 Snps",
              "type": "variant",
              "format": "vcf",
              "url": "https://s3.amazonaws.com/1000genomes/release/20130502/ALL.wgs.phase3_shapeit2_mvncall_integrated_v5b.20130502.sites.vcf.gz?versionId=null",
              "indexURL": "https://s3.amazonaws.com/1000genomes/release/20130502/ALL.wgs.phase3_shapeit2_mvncall_integrated_v5b.20130502.sites.vcf.gz.tbi?versionId=null",
              //"visibilityWindow": 20000,
            },
            {
              "name": "PharmGKB",
              "type": "annotation",
              "format": "pharmgkb",
              "displayMode": "EXPANDED",
              "url": data_path+"/DrugResponse_PharmGKB_hg19_sorted_bed/Drug_Response_Chr"+chrN+".bed.gz",
              "indexURL": data_path+"/DrugResponse_PharmGKB_hg19_sorted_bed/Drug_Response_Chr"+chrN+".bed.gz.tbi",
              'height':100
            },
            {
              "name": "DrugAction(DrugBank)",
              "type": "annotation",
              "format": "drug_action",
              "displayMode": "EXPANDED",
              "url": data_path+"/Drug_Action_Drugbank/Drug_Action_Chr"+chrN+".bed.gz",
              "indexURL": data_path+"/Drug_Action_Drugbank/Drug_Action_Chr"+chrN+".bed.gz.tbi",
            },
            {
              "name": "Gene Ontology Terms",
              "type": "annotation",
              "format": "id_name",
              "displayMode": "EXPANDED",
              "url": data_path + "/GO_hg19_sorted_bed/" + chrstr + ".bed.gz",
              "indexURL": data_path + "/GO_hg19_sorted_bed/" + chrstr + ".bed.gz.tbi",
              "height":100,
            },
            {
              "name": "Transcription Factor Binding Sites",
              "type": "annotation",
              "format": "tfbs",
              "height":100,
              "displayMode": "EXPANDED",
              "url": data_path + "/TFBS_ucsc_encode_hg19_sorted_bed/" + chrstr + ".bed.gz",
              "indexURL": data_path + "/TFBS_ucsc_encode_hg19_sorted_bed/" + chrstr + ".bed.gz.tbi",
            },
            {
              "name": "KEGG Pathways",
              "type": "annotation",
              "format": "id_name",
              "displayMode": "EXPANDED",
              "url": data_path + "/KEGGpathway_hg19_sorted_bed/" + chrstr + ".bed.gz",
              "indexURL": data_path + "/KEGGpathway_hg19_sorted_bed/" + chrstr + ".bed.gz.tbi",
            },
            {
              "name": "KEGG Diseases",
              "type": "annotation",
              "format": "id_name",
              "displayMode": "EXPANDED",
              "url": data_path + "/KEGGdisease_hg19_sorted_bed/" + chrstr + ".bed.gz",
              "indexURL": data_path + "/KEGGdisease_hg19_sorted_bed/" + chrstr + ".bed.gz.tbi",
              'height':100
            },
            {
              "name": "Enhancer",
              "url": data_path+"/Enhancer_EpiMap_hg19_sorted_bed/"+chrstr+".bed.gz",
              "indexURL": data_path+"/Enhancer_EpiMap_hg19_sorted_bed/"+chrstr+".bed.gz.tbi",
              "type": "annotation",
              "format": "enhancer",
              //"color":"rgb(0,0,255)",
              //"autoHeight":true,
            },
            {
              "name": "GWAS Sites",
              "type": "annotation",
              "format": "gwasCatalog",
              "displayMode": "COLLAPSED",
              "visibilityWindow": -1,
              // "color":"rgb(255,0,0)", //it works
              "url": data_path + "/GWAS_hg19_sorted_gwas/" + chrstr + ".gwas.gz",
              "autoHeight":true,
            },
            {
              "name": "Quantitative Trait Loci",
              "type": "annotation",
              "format": "qtl",
              "displayMode": "COLLAPSED",
              "visibilityWindow": -1,
              // "color":"rgb(255,0,0)", //it works
              "url": data_path + "/QTL_QTLbase_url4Detail_hg19_sorted_bed/" + chrstr + ".bed.gz",
              //"indexURL": "",
              "autoHeight": true,
            },
            {
              "name": "CpG Islands",
              "type": "annotation",
              "format": "cpgIslandExt",
              "displayMode": "EXPANDED",
              "url": "https://s3.amazonaws.com/igv.org.genomes/hg19/annotations/cpgIslandExt.txt.gz",
              "indexed": false
            },
          ]
        };

    igv.createBrowser(igvDiv, options)
        .then(function (browser) {
          browser.on('trackclick', function (track, popoverData) {

            var markup = '<table class="styled-table">';

            // Don't show a pop-over when there's no data.
            if (!popoverData || !popoverData.length) {
              return false;
            }

            popoverData.forEach(function (nameValue) {

              if (nameValue.name) {
                var value = nameValue.name.toLowerCase() === 'detail'
                    ? '<a href="http://www.mulinlab.org/qtlbase/rqtls.html?q=chr' + nameValue.value + '&t=1&g=hg19&d=0">QTLbase</a>'
                    : nameValue.value;

                // markup += "<tr><td><th align='left'>" + nameValue.name + "</th></td><td>" + value + "</td></tr>";
                markup += "<tr><td>" + nameValue.name + "</td><td>" + value + "</td></tr>";
              } else if (nameValue.html) {
                markup += "<tr><td>" + nameValue.html + "</td></tr>";
              } else {
                // not a name/value pair
                markup += "<tr><td>" + nameValue.toString() + "</td></tr>";
              }
            });

            markup += "</table>";

            // By returning a string from the trackclick handler we're asking IGV to use our custom HTML in its pop-over.
            return markup;
          });
        })
  },
  methods: {
    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <el-row type="flex" justify="center">
    <el-col :xl=16 :lg="18">
      <el-card shadow="always" class="box-card">
        <el-row>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item style="font-size: large">PopTradeOff</el-breadcrumb-item>
            <el-breadcrumb-item style="font-size: large">Data Summary</el-breadcrumb-item>
          </el-breadcrumb>
        </el-row>
        <el-divider></el-divider>
        <p>The data that users can search and browse on PopTradeOff are summarized here.</p>
        <h3>1. Numbers of favored and hitchhiking neutral mutations in different populations</h3>
        <el-row>
          <el-col :span="24" align="center">
            <el-image :src="img6" style="height: 400px"></el-image>
          </el-col>
        </el-row>
        <div><span style="font-weight: bolder">Figure legend: </span>hitchhiking mutations were identified by training the deep learning network DeepFavored using the demographic models of 17 human populations and applying the trained model to the <i class="el-icon-link" style="color: #409EFF"></i><a
              href="https://www.internationalgenome.org/home">1000 Genomes Project (Phase 3)</a> data, see (<i class="el-icon-document" style="color: #409EFF"></i><a href="https://www.cell.com/cell-reports/fulltext/S2211-1247(22)01179-2?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS2211124722011792%3Fshowall%3Dtrue">Tang et al., 2022</a>)
          for more details. The 17 populations include Utah residents with Northern and Western European ancestry (CEU), Finnish in Finland (FIN), British in England and Scotland (GBR), Iberian populations in Spain (IBS), Toscani in Italia (TSI), Han Chinese in Beijing (CHB), Chinese Dai in Xishuangbanna (CDX), Southern Han Chinese (CHS), Japanese in Tokyo (JPT), Kinh Vietnamese (KHV), Yoruba in Ibadan, Nigeria (YRI), African Caribbean in Barbados (ACB), Luhya in Webuye, Kenya (LWK), Mende in Sierra Leone (MSL), African Ancestry in Southwest US (ASW), Esan in Nigeria (ESN), Gambian in Western Division, The Gambia (GWD).
        </div>
        <h3>2. The distribution of favored and hitchhiking neutral mutations in different genes, gene sets, and genomic regions</h3>
        <el-row>
          <el-col :span="24" align="center">
            <el-image :src="img7" style="height: 400px"></el-image>
          </el-col>
        </el-row>
        <div><span style="font-weight: bolder">Figure legend: </span>Gene biotypes and genomic regions are from <i
              class="el-icon-link" style="color: #409EFF"></i><a href="http://grch37.ensembl.org/Homo_sapiens/Info/Index">Ensembl</a> gene annotation file (GRCh37 release 87). The promoters are defined as 2000 bp upstream (on the plus strand) or downstream (on the minus strand) the transcriptional start sites. GO_term and KEGG_pathway genes were from the<i class="el-icon-link" style="color: #409EFF"></i><a
              href="https://biit.cs.ut.ee/gprofiler/gost">g:Profiler</a> website and <i class="el-icon-link"
                                                                                        style="color: #409EFF"></i><a
              href="https://www.genome.jp/kegg/pathway.html">KEGG pathway</a> database, respectively.
        </div>
        <h3>3. Numbers of regulatory loci that overlap favored and hitchhiking mutations across tissues or cell types</h3>
        <el-row>
          <el-col :span="24" align="center">
            <el-image :src="img8" style="height: 600px"></el-image>
          </el-col>
        </el-row>
        <div><span style="font-weight: bolder">Figure legend: </span>Horizontal axes denote numbers of target genes of regulatory loci that overlap favored and hitchhiking mutations. The Enhancer annotations are from the <i class="el-icon-link" style="color: #409EFF"></i><a
              href="http://compbio.mit.edu/epimap/">EpiMap</a> website. The Quantitative trait loci (QTL) data are from the <i class="el-icon-link" style="color: #409EFF"></i><a
              href="http://www.mulinlab.org/qtlbase">QTLbase</a> database. The Transcription factor binding sites (TFBS) data are downloaded from the <i class="el-icon-link" style="color: #409EFF"></i><a href="https://genome.ucsc.edu/">UCSC genome
          browser</a> website, which collects the Transcription Factor ChIP-seq Clusters (including 338 transcription factors and 130 cell types) from the <i class="el-icon-link" style="color: #409EFF"></i><a
              href="https://www.encodeproject.org/">ENCODE</a> project. . The 130 cell types are classified into 33 groups according to main_metadata_table.tsv in the<i class="el-icon-link" style="color: #409EFF"></i><a
              href="http://compbio.mit.edu/epimap/">EpiMap</a> website. The nearest gene of each TFBS is treated as the target gene.
        </div>
        <h3>4. Numbers of favored and hitchhiking mutations that are potentially related to diseases</h3>
        <h4>4.1 Upon GWAS Catalog data</h4>
        <el-row>
          <el-col :span="24" align="center">
            <el-image :src="img9" style="height: 300px"></el-image>
          </el-col>
        </el-row>
        <h4>4.2 Upon KEGG disease data</h4>
        <el-row>
          <el-col :span="24" align="center">
            <el-image :src="img10" style="height: 300px"></el-image>
          </el-col>
        </el-row>
        <div><span style="font-weight: bolder">Figure legend: </span>Section 4.1 shows the number of favored and hitchhiking mutations overlapping different classes of GWAS sites (data are from <i class="el-icon-link" style="color: #409EFF"></i><a href="https://www.ebi.ac.uk/gwas/">NHGRI-EBI GWAS Catalog</a>).
          Section 4.2 shows the number of favored and hitchhiking mutations located in genes of different KEGG disease (genes are from the <i class="el-icon-link" style="color: #409EFF"></i><a href="https://www.genome.jp/kegg/disease/">KEGG disease</a> database).
        </div>
        <h3>5. Numbers of favored and hitchhiking mutations that are potentially related to drug response/action</h3>
        <el-row>
          <el-col :span="24" align="center">
            <el-image :src="img11" style="height: 600px"></el-image>
          </el-col>
        </el-row>
        <div><span style="font-weight: bolder">Figure legend: </span>Numbers of favored and hitchhiking mutations that overlap SNPs associated with drug responses or drug actions. SNPs associated with drug responses are from the <i class="el-icon-link" style="color: #409EFF"></i><a href="https://www.pharmgkb.org/">PharmGKB</a> database and <i class="el-icon-link" style="color: #409EFF"></i><a href="https://www.ebi.ac.uk/gwas/">GWAS</a> database.
          SNPs associated with drug actions (in the exons or overlap eQTLs of drug action-related genes) are from the <i class="el-icon-link" style="color: #409EFF"></i><a href="https://go.drugbank.com/targets">DrugBank</a> database. Drug action-related genes include protein targets of drug action (Target), enzymes that are inhibited/induced or involved in metabolism (Enzyme), and carrier or transporter proteins involved in movement of the drug across biological membranes (Carrier and Transporter).
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "PopFMSumm",
  data() {
    return {
      img6: require('../assets/popfm_pic6.png'),
      img7: require('../assets/popfm_pic7.png'),
      img8: require('../assets/popfm_pic8.png'),
      img9: require('../assets/popfm_pic9.png'),
      img10: require('../assets/popfm_pic10.png'),
      img11: require('../assets/popfm_pic11.png'),
    }
  },
}
</script>

<style scoped>
.box-card {
  min-width: 100%;
  height: 100%;
  margin-right: 20px;
}
</style>

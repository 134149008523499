import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import router from './router'
import axios from 'axios'
import App from './App.vue'
import VueHotspot from 'vue-hotspot'
Vue.component('v-hotspot', VueHotspot)

Vue.use(ElementUI, {locale})
Vue.config.productionTip = false

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'http://www.gaemons.net:8000' : 'http://localhost:8000'

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')

<template>
  <el-row type="flex" justify="center">
    <el-col :lg="16" :xl="12">
      <el-tabs v-loading="loading" type="border-card">
        <el-tab-pane label="Brief Info">
          <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item label="Organism">
              {{ gene.species }}
            </el-descriptions-item>
            <el-descriptions-item label="Ensembl/NCBI Gene ID">
              {{ gene.gtf_gene_id }}
            </el-descriptions-item>
            <el-descriptions-item label="HGNC gene symbol">
              {{ gene.gene_name }}
            </el-descriptions-item>
            <el-descriptions-item label="Locus">
              {{ gene.seqname }}:{{ gene.start }}-{{ gene.end }}
            </el-descriptions-item>
            <el-descriptions-item label="gene_name (alias_name)">
              {{ gene.alias }}
            </el-descriptions-item>
            <el-descriptions-item label="Description">
            </el-descriptions-item>
            <el-descriptions-item content-style="word-break:break-all;" label="Sequence">
              {{ seqData }}
            </el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="Exon positions">
          <el-table v-loading="loading" :data="exonList" border style="width: 60%"
                    :cell-style="{'text-align':'center'}" :header-cell-style="{'text-align':'center'}">
            <el-table-column type="index" label="Exon number" width="120"></el-table-column>
            <el-table-column prop="start" label="Start" width="120"></el-table-column>
            <el-table-column prop="end" label="End" width="120"></el-table-column>
            <el-table-column prop="strand" label="Strand" width="120"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Text display">
          <el-tabs v-model="exontabvalue">
            <el-tab-pane v-for="(item,index) in cmalignList" :key="index" :label="item.label" :name="item.index">
              <el-button type="primary" size="small" icon="el-icon-arrow-left" @click="sequp"></el-button>
              Alignment position: {{ item.start + 1 }}&lt;--&gt;{{ item.end + 1 }}, Alignment length: {{item.seqlength}}, Step: {{ item.step }}
              <el-button type="primary" size="small" icon="el-icon-arrow-right" @click="seqdown"></el-button>
              <el-table v-loading="loading" :data="item.view" border
                        style="display: inline-block;white-space: nowrap;word-break: keep-all;font-family: SourceCodePro"
                        :header-cell-style="{'text-align':'center'}">
                <el-table-column prop="species" label="Species" fixed width="120" align="center"></el-table-column>
                <el-table-column prop="score" label="Score" fixed width="100" align="center"></el-table-column>
                <el-table-column prop="seq" label="Sequence Alignment" align="center">
                  <template slot-scope="scope">
                    <el-tag size="small" type="success">{{ scope.row.seq.substring(item.start, item.end) }}</el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
<!--        <el-tab-pane label="Graphic display">
        </el-tab-pane>-->
      </el-tabs>
    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios'

export default {
  name: "LongManShow",
  data() {
    return {
      gene: [],
      seqData: '',
      exonList: [],
      cmalignList: [],
      loading: true,
      exontabvalue: '1',
    }
  },
  methods: {
    getexonlist() {
      return axios.get('/longman/exonlist/', {params: {geneid: this.gene.id}})
    },
    gethomoexonlist() {
      return axios.get('/longman/homoexonlist/', {params: {geneid: this.gene.id}})
    },
    getseqence() {
      return axios.get('/longman/getsequence/', {params: {speciesid: this.gene.species_id, geneid: this.gene.id}})
    },
    getcmalign() {
      return axios.get('/longman/getcmalign/',
          {params: {geneid: this.gene.species_id == 1 ? this.gene.id : this.gene.gene_id}})
    },
    sequp() {
      let i = parseInt(this.exontabvalue) - 1
      if (this.cmalignList[i].start - this.cmalignList[i].step < 0) {
        this.cmalignList[i].start = 0
        this.cmalignList[i].end = this.cmalignList[i].interval >= this.cmalignList[i].seqlength ?
            this.cmalignList[i].seqlength - 1 : this.cmalignList[i].start + this.cmalignList[i].interval - 1
      } else {
        this.cmalignList[i].start = this.cmalignList[i].start - this.cmalignList[i].step
        this.cmalignList[i].end = (this.cmalignList[i].start + this.cmalignList[i].interval) >= this.cmalignList[i].seqlength ?
            this.cmalignList[i].seqlength - 1 : this.cmalignList[i].start + this.cmalignList[i].interval - 1
      }
    },
    seqdown() {
      let i = parseInt(this.exontabvalue) - 1
      if (this.cmalignList[i].start + this.cmalignList[i].interval < this.cmalignList[i].seqlength
          && this.cmalignList[i].start + this.cmalignList[i].step < this.cmalignList[i].seqlength) {
        this.cmalignList[i].start = this.cmalignList[i].start + this.cmalignList[i].step
        this.cmalignList[i].end = (this.cmalignList[i].start + this.cmalignList[i].interval) >= this.cmalignList[i].seqlength ?
            this.cmalignList[i].seqlength - 1 : this.cmalignList[i].start + this.cmalignList[i].interval - 1
      }
    }
  },
  mounted() {
    let that = this
    that.gene = that.$route.query.value
    if (that.gene.species_id == 1) {
      axios
          .all([that.getexonlist(), that.getseqence(), that.getcmalign()])
          .then(axios.spread(function (res1, res2, res3) {
            that.exonList = res1.data
            that.seqData = res2.data.seq
            that.cmalignList = res3.data
            that.loading = false
          }))
          .catch(function (err) {
            console.log(err)
          })
    } else {
      axios
          .all([that.gethomoexonlist(), that.getseqence(), that.getcmalign()])
          .then(axios.spread(function (res1, res2, res3) {
            that.exonList = res1.data
            that.seqData = res2.data.seq
            that.cmalignList = res3.data
            that.loading = false
          }))
          .catch(function (err) {
            console.log(err)
          })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <el-row type="flex" justify="center" :gutter="10">
    <el-col :xl="12" :lg="16">
      <el-card shadow="always">
        <h3>1.Input&nbsp;DNA&nbsp;Sequence</h3>
        <div>Define a genomic region:</div>
        <el-row :gutter="10" type="flex" align="middle">
          <el-col :span="2" align="right"><p>Species</p></el-col>
          <el-col :span="4">
            <el-select v-model="species" size="small">
              <el-option v-for="item in speciesItems" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-col>
          <el-col :span="1" align="right"><p>Chr</p></el-col>
          <el-col :span="3">
            <el-select v-model="chr" size="small">
              <el-option v-for="item in chrItems[speciesItems.find(array => array.value === species).id]"
                         :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2" align="right"><p>From</p></el-col>
          <el-col :span="4">
            <el-input v-model="start" size="small" onkeyup="value=value.replace(/[^0-9]/g,'')"></el-input>
          </el-col>
          <el-col :span="1" align="right"><p>To</p></el-col>
          <el-col :span="4">
            <el-input v-model="end" size="small" onkeyup="value=value.replace(/[^0-9]/g,'')"></el-input>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" icon="el-icon-download" size="small" @click="getSequence()">Retrieve</el-button>
          </el-col>
        </el-row>
        <div>Or paste a DNA sequence (&lt;2000000bp) in fasta format into the input-box:
          <el-popover placement="bottom" trigger="hover" width="300">
            <div style="word-break: normal">A DNA sequence should have a title line in the format
              ">species|chr|start-end" without any space between letters, and species name should be concise. To enable
              "Draw Previews" (see the "Draw figure" button below, the abbreviated genomic version (currently hg19,
              hg38, mm10, panTro4, bosTau7, canFam3, calJac3, monDom5, oryCun2, rn5 and rheMac3) should be in the title
              line for choosing correct genomic version.
            </div>
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </div>
        <el-input type="textarea" rows="8" v-model="jobForm.dna_info" resize="none" clearable></el-input>
        <div>Or choose a species for genome-scale prediction for the inputted lncRNA:
          <el-popover placement="bottom" trigger="hover" width="300">
            <div style="word-break: normal">Choose a species for genome-wide predicting an lncRNAs’ DNA binding sites in
              5000 bp promoter regions of all transcripts. Mammals whose genomes are assembled only into scaffolds and
              contigs will be included soon.
            </div>
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </div>
        <el-row :gutter="10" type="flex" align="middle">
          <el-col :span="2" align="right"><p>Species</p></el-col>
          <el-col :span="4">
            <el-select v-model="genome" size="small">
              <el-option v-for="item in speciesItems" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" icon="el-icon-check" size="small" @click="getGenome()">Choose</el-button>
          </el-col>
        </el-row>
        <h3>2.Input LncRNA Sequence</h3>
        <div>Paste a LncRNA sequence in fasta format into the input-box:
          <el-popover placement="bottom" trigger="hover" width="300">
            <div style="word-break: normal">An RNA sequence should have a title line in the format ">species_lncRNA"
              without any space within letters, and “_” cannot be replaced by "-".
            </div>
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </div>
        <el-input type="textarea" rows="8" v-model="jobForm.rna_info" resize="none" clearable></el-input>
        <h3>3.Select a base pairing rule set</h3>
        <el-select v-model="jobForm.rule">
          <el-option-group
              v-for="group in pairRuleItems"
              :key="group.label"
              :label="group.label">
            <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-option-group>
        </el-select>
        <h3>4.Set up parameters</h3>
        <el-row :gutter="10" type="flex" align="middle">
          <el-col :span="8">
            <div>TT penalty<br/>
              <el-input v-model="jobForm.tpenalty" size="small">
                <template slot="prepend">eq</template>
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div>CC penalty<br/>
              <el-input v-model="jobForm.cpenalty" size="small">
                <template slot="prepend">eq</template>
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div>Offset<br/>
              <el-input v-model="jobForm.offset" size="small">
                <template slot="prepend">eq</template>
              </el-input>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="10" type="flex" align="middle">
          <el-col :span="8">
            <div>Identity<br/>
              <el-input v-model="jobForm.identity" size="small">
                <template slot="prepend">min</template>
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div>Nt<br/>
              <el-input v-model="jobForm.nt" size="small">
                <template slot="prepend">min</template>
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div>Mean stability<br/>
              <el-input v-model="jobForm.stability" size="small">
                <template slot="prepend">min</template>
              </el-input>
            </div>
          </el-col>
        </el-row>
        <h3>5.Select a program</h3>
        <el-select v-model="jobForm.result" size="small">
          <el-option v-for="item in programItems" :key="item.value" :label="item.label"
                     :value="item.value"></el-option>
        </el-select>

        <h3>6.User's email (optional)</h3>
        <el-row>
          <el-col :span="12">
            <el-input v-model="jobForm.email" size="small"></el-input>
          </el-col>
        </el-row>
        <el-row style="margin: 20px" :gutter="10">
          <el-col :span="12" align="right">
            <el-button type="primary" icon="el-icon-check" @click="submit">Submit</el-button>
          </el-col>
          <el-col :span="12" align="left">
            <el-button type="primary" @click="queryJob">Retrieve Result</el-button>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
    <el-col :xl="6" :lg="6">
      <el-card shadow="always">
        <div>
          <h3>Browser Compatibility</h3>
          <p>Functions of this website are tested to work well with Google Chrome and Mozilla Firefox, so we recommend
            the two browsers.</p>
          <h3>How and when to get results</h3>
          <p>1. A job number is generated when the job is submitted, allowing the user to retrieve and download the
            result. It is optional (specifically indicated by “*”) for the user to leave an email address, allowing the
            result to be sent to the email address automatically whenever it is finished.</p>
          <p>2. Normally it takes about 1 hour to predict a lncRNA/DNA binding in a genomic region &le;1,000,000 bp, and
            about 3-4 days to predict a lncRNA/DNA binding in a whole genome. However, time consumption may vary,
            especially for genome-scale prediction, depending on the workload of the server.</p>
          <p>3. Be careful to choose “Shuffle DNA sequence” and “Shuffle RNA sequence”, the two functions test
            statistical robustness of results, are very time-consuming (100 times the normal time-consumption), and are
            normally unnecessary (see an example in the supplementary material of <i class="el-icon-document"
                                                                                     style="color: blue"></i><a
                href="https://academic.oup.com/bioinformatics/article/31/2/178/2366221">He et al. Bioinformatics 2015,
              31:178–186).</a></p>
          <h3>Related citations</h3>
          <p>
            <i class="el-icon-document" style="color: blue"></i><a
              href="https://linkinghub.elsevier.com/retrieve/pii/S2001037022002318">
            Wen Y, Wu Y, Xu B, Lin J, Zhu H. Fasim-LongTarget enables fast and accurate genome-wide lncRNA/DNA binding
            prediction. Comput Struct Biotechnol J. 2022 Jun 18;20:3347-3350.</a><br/>
            <i class="el-icon-document" style="color: blue"></i><a
              href="https://www.nature.com/articles/s41596-018-0115-5">
            Lin J, Wen Y, He S, Yang X, Zhang H, Zhu H. Pipelines for cross-species and genome-wide prediction of long
            noncoding RNA binding. Nat Protoc. 2019 Mar;14(3):795-818.
          </a><br/>
            <i class="el-icon-document" style="color: blue"></i><a
              href="https://academic.oup.com/bioinformatics/article/33/10/1431/2801463">Liu H, Shang X, Zhu H.
            LncRNA/DNA binding analysis reveals losses and gains and lineage specificity of genomic imprinting in
            mammals. Bioinformatics 2017, 33:1431–1436.</a><br/>
            <i class="el-icon-document" style="color: blue"></i><a
              href="https://academic.oup.com/bioinformatics/article/31/2/178/2366221">He S, Zhang H, Liu H, Zhu H.
            LongTarget: a tool to predict lncRNA DNA-binding motifs and binding sites via Hoogsteen base-pairing
            analysis. Bioinformatics 2015, 31:178–186.</a>
          </p>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios'

export default {
  name: "LongTarget",
  data() {
    return {
      speciesItems: [
        {id: 0, label: 'human hg19', value: 'hg19'},
        {id: 1, label: 'human hg38', value: 'hg38'},
        {id: 2, label: 'mouse mm10', value: 'mm10'},
        {id: 3, label: 'chimp panTro4', value: 'panTro4'},
        {id: 4, label: 'cow bosTau7', value: 'bosTau7'},
        {id: 5, label: 'dog canFam3', value: 'canFam3'},
        {id: 6, label: 'marmoset calJac3', value: 'calJac3'},
        {id: 7, label: 'opossum monDom5', value: 'monDom5'},
        {id: 8, label: 'rabbit oryCun2', value: 'oryCun2'},
        {id: 9, label: 'rat rn5', value: 'rn5'},
        {id: 10, label: 'macaque macFas5', value: 'rheMac3'},
        {id: 11, label: 'mouse lemur micMur2', value: 'micMur2'},
        {id: 12, label: 'platypus ornAna2', value: 'ornAna2'}
      ],
      chrItems: [
        [/*hg19*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chr22', value: 'chr22'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrY', value: 'chrY'},
          {label: 'chrM', value: 'chrM'},
        ],
        [/*hg38*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chr22', value: 'chr22'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrY', value: 'chrY'},
          {label: 'chrM', value: 'chrM'}
        ],
        [/*mm10*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrY', value: 'chrY'},
          {label: 'chrM', value: 'chrM'}
        ],
        [/*panTro4*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2A', value: 'chr2A'},
          {label: 'chr2B', value: 'chr2B'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chr22', value: 'chr22'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrY', value: 'chrY'},
          {label: 'chrM', value: 'chrM'}
        ],
        [/*bosTau7*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chr22', value: 'chr22'},
          {label: 'chr23', value: 'chr23'},
          {label: 'chr24', value: 'chr24'},
          {label: 'chr25', value: 'chr25'},
          {label: 'chr26', value: 'chr26'},
          {label: 'chr27', value: 'chr27'},
          {label: 'chr28', value: 'chr28'},
          {label: 'chr29', value: 'chr29'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrY', value: 'chrY'},
          {label: 'chrM', value: 'chrM'}
        ],
        [/*canFam3*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chr22', value: 'chr22'},
          {label: 'chr23', value: 'chr23'},
          {label: 'chr24', value: 'chr24'},
          {label: 'chr25', value: 'chr25'},
          {label: 'chr26', value: 'chr26'},
          {label: 'chr27', value: 'chr27'},
          {label: 'chr28', value: 'chr28'},
          {label: 'chr29', value: 'chr29'},
          {label: 'chr30', value: 'chr30'},
          {label: 'chr31', value: 'chr31'},
          {label: 'chr32', value: 'chr32'},
          {label: 'chr33', value: 'chr33'},
          {label: 'chr34', value: 'chr34'},
          {label: 'chr35', value: 'chr35'},
          {label: 'chr36', value: 'chr36'},
          {label: 'chr37', value: 'chr37'},
          {label: 'chr38', value: 'chr38'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrM', value: 'chrM'}
        ],
        [/*calJac3*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chr22', value: 'chr22'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrY', value: 'chrY'}
        ],
        [/*monDom5*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chrX', value: 'chrX'},
        ],
        [/*oryCun2*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrM', value: 'chrM'}
        ],
        [/*rn5*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrM', value: 'chrM'}
        ],
        [/*rheMac3*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrM', value: 'chrM'}
        ],
        [/*micMur2*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chr21', value: 'chr21'},
          {label: 'chr22', value: 'chr22'},
          {label: 'chr23', value: 'chr23'},
          {label: 'chr24', value: 'chr24'},
          {label: 'chr25', value: 'chr25'},
          {label: 'chr26', value: 'chr26'},
          {label: 'chr27', value: 'chr27'},
          {label: 'chr28', value: 'chr28'},
          {label: 'chr29', value: 'chr29'},
          {label: 'chr30', value: 'chr30'},
          {label: 'chr31', value: 'chr31'},
          {label: 'chr32', value: 'chr32'},
          {label: 'chrX', value: 'chrX'},
          {label: 'chrM', value: 'chrM'}
        ],
        [/*ornAna2*/
          {label: 'chr1', value: 'chr1'},
          {label: 'chr2', value: 'chr2'},
          {label: 'chr3', value: 'chr3'},
          {label: 'chr4', value: 'chr4'},
          {label: 'chr5', value: 'chr5'},
          {label: 'chr6', value: 'chr6'},
          {label: 'chr7', value: 'chr7'},
          {label: 'chr8', value: 'chr8'},
          {label: 'chr9', value: 'chr9'},
          {label: 'chr10', value: 'chr10'},
          {label: 'chr11', value: 'chr11'},
          {label: 'chr12', value: 'chr12'},
          {label: 'chr13', value: 'chr13'},
          {label: 'chr14', value: 'chr14'},
          {label: 'chr15', value: 'chr15'},
          {label: 'chr16', value: 'chr16'},
          {label: 'chr17', value: 'chr17'},
          {label: 'chr18', value: 'chr18'},
          {label: 'chr19', value: 'chr19'},
          {label: 'chr20', value: 'chr20'},
          {label: 'chrX1', value: 'chrX1'},
          {label: 'chrX2', value: 'chrX2'},
          {label: 'chrX3', value: 'chrX3'},
          {label: 'chrX5', value: 'chrX5'},
        ],
      ],
      pairRuleItems: [
        {
          label: 'All',
          options: [
            {label: 'All--default', value: 0}
          ]
        },
        {
          label: 'H',
          options: [
            {label: 'H1:TA-T,AT-G,CG-G,GC-T', value: 1},
            {label: 'H2:TA-T,AT-G,CG-C,GC-T', value: 2},
            {label: 'H3:TA-T,AT-G,CG-T,GC-T', value: 3},
            {label: 'H4:TA-T,AT-G,CG-G,GC-C', value: 4},
            {label: 'H5:TA-T,AT-G,CG-C,GC-C', value: 5},
            {label: 'H6:TA-T,AT-G,CG-T,GC-C', value: 6}
          ]
        },
        {
          label: 'RH',
          options: [
            {label: 'RH1:TA-T,AT-G,CG-G,GC-T', value: 7},
            {label: 'RH2:TA-T,AT-G,CG-C,GC-T', value: 8},
            {label: 'RH3:TA-T,AT-G,CG-A,GC-T', value: 9},
            {label: 'RH4:TA-T,AT-G,CG-G,GC-C', value: 10},
            {label: 'RH5:TA-T,AT-G,CG-C,GC-C', value: 11},
            {label: 'RH6:TA-T,AT-G,CG-A,GC-C', value: 12},
            {label: 'RH7:TA-A,AT-G,CG-G,GC-T', value: 13},
            {label: 'RH8:TA-A,AT-G,CG-C,GC-T', value: 14},
            {label: 'RH9:TA-A,AT-G,CG-A,GC-T', value: 15},
            {label: 'RH10:TA-A,AT-G,CG-G,GC-C', value: 16},
            {label: 'RH11:TA-A,AT-G,CG-C,GC-C', value: 17},
            {label: 'RH12:TA-A,AT-G,CG-A,GC-C', value: 18},
            {label: 'RH13:TA-C,AT-G,CG-G,GC-T', value: 19},
            {label: 'RH14:TA-C,AT-G,CG-C,GC-T', value: 20},
            {label: 'RH15:TA-C,AT-G,CG-A,GC-T', value: 21},
            {label: 'RH16:TA-C,AT-G,CG-G,GC-C', value: 22},
            {label: 'RH17:TA-C,AT-G,CG-C,GC-C', value: 23},
            {label: 'RH18:TA-C,AT-G,CG-A,GC-C', value: 24}
          ]
        }
      ],
      programItems: [
        {label: 'LongTarget', value: '-3'},
        {label: 'Fasim-LongTarget', value: '-4'}
      ],
      species: 'hg19',
      chr: 'chr1',
      start: '',
      end: '',
      genome: '',
      jobForm: {
        analysisID: null,
        rna_info: '',
        dna_info: '',
        rule: 0,
        tpenalty: -1000,
        cpenalty: 0,
        offset: 15,
        identity: 60,
        nt: 50,
        stability: 1,
        result: '-3',
        email: ''
      }
    }
  },
  methods: {
    getSequence() {
      if (this.start == '' || this.end == '') {
        this.$message({
          message: 'The start or end position is empty!',
          type: "warning",
        })
        return
      }
      if (parseInt(this.start) >= parseInt(this.end)) {
        this.$message({
          message: 'The start position must less than the end!!',
          type: "warning",
        })
        return
      }
      let that = this
      axios
          .get('http://api.genome.ucsc.edu/getData/sequence?' + 'genome=' + that.species + '&chrom=' + that.chr + '&start=' + that.start + '&end=' + that.end)
          .then(function (res) {
            that.jobForm.dna_info = '>' + that.species + '|' + that.chr + '|' + that.start + '-' + that.end + '\n' + res.data.dna
          })
          .catch(function (err) {
            that.$message({
              message: err,
              type: "error"
            })
          })
    },
    getGenome() {
      if (this.genome != '') {
        this.jobForm.dna_info = 'The prediction will be performed in the region of -3500~+1500 bp upstream/downstream TSS of all transcripts in ' + this.genome + '.'
      }
    },
    clear() {
      this.jobForm.rna_info = ''
      this.jobForm.dna_info = ''
      this.jobForm.rule = 0
      this.jobForm.tpenalty = -1000
      this.jobForm.cpenalty = 0
      this.jobForm.offset = 15
      this.jobForm.identity = 60
      this.jobForm.nt = 50
      this.jobForm.stability = 1
      this.jobForm.email = ''
    },
    submit() {
      if (this.jobForm.dna_info == '') {
        this.$message({
          message: 'DNA sequence is null',
          type: "warning",
        })
        return
      }
      if (/^>[a-zA-Z0-9]+\|chr[a-zA-Z0-9]+\|[0-9]+-[0-9]+\n[atgcnATGCN]+/.test(this.jobForm.dna_info) == false &&
          /^The\sprediction\swill\sbe\sperformed\sin\sthe\sregion\sof.+/.test(this.jobForm.dna_info) == false) {
        this.$message({
          message: 'Wrong fasta format in DNA sequence',
          type: "warning",
        })
        return
      }
      if (this.jobForm.rna_info == '') {
        this.$message({
          message: 'LncRNA sequence is null',
          type: "warning",
        })
        return
      }
      if (/^>[a-zA-Z0-9_-]+\n[atgcnATGCN]+/.test(this.jobForm.rna_info) == false) {
        this.$message({
          message: 'Wrong fasta format in LncRNA sequence',
          type: "warning",
        })
        return
      }
      if (this.jobForm.email != '') {
        if (/^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.jobForm.email) == false) {
          this.$message({
            message: 'Bad email address',
            type: "warning",
          })
          return
        }
      }
      this.addJob()
    },
    addJob() {
      let that = this
      let date = new Date()
      that.jobForm.analysisID = parseInt(date.getFullYear().toString() +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          date.getDate().toString().padStart(2, '0') +
          date.getHours().toString().padStart(2, '0') +
          date.getMinutes().toString().padStart(2, '0') +
          date.getSeconds().toString().padStart(2, '0') +
          date.getMilliseconds().toString().padStart(3, '0'))
      let myForm = Object.assign({}, that.jobForm)
      if (myForm.email == '') {
        myForm.email = undefined
      }
      axios
          .post('/longtarget/jobs/', myForm)
          .then(function () {
            that.$message({
              message: 'Job submits successful. ' + 'Your job ID is ' + that.jobForm.analysisID + '. '
                  + 'Please remember it.',
              type: "success",
              showClose: true,
              duration: 0
            })
            that.clear()
          })
          .catch(function (err) {
            that.$message({
              message: err,
              type: "error"
            })
          })
    },
    queryJob() {
      this.$router.push('/QueryJob')
    }
  }
}
</script>

<style scoped>
.box-card {
  min-width: 100%;
  height: 100%;
  margin-right: 20px;
}

.icon-warp {
  font-size: 150%;
}
</style>

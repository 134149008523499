<template>
  <el-row type="flex" justify="center">
    <el-col :lg="16" :xl="12">
      <el-card shadow="always">
        <el-button icon="el-icon-arrow-left" size="small" type="primary" @click="goBack">Back</el-button>
        <el-row :gutter="10" type="flex" align="middle">
          <el-col :span="4">
            <p>Enter analysis ID:</p>
          </el-col>
          <el-col :span="4">
            <el-input v-model="analysisId" size="small"></el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" size="small" @click="getJob">Retrieve</el-button>
          </el-col>
        </el-row>
        <el-descriptions class="margin-top" title="Job Detail" :column="3" border>
          <el-descriptions-item label="Job ID" span="2">
            {{ job.analysisID }}
          </el-descriptions-item>
          <el-descriptions-item label="status">
            {{ job.job_status }}
          </el-descriptions-item>
          <el-descriptions-item label="rule" span="3">
            {{ pariRules[job.rule] }}
          </el-descriptions-item>
          <el-descriptions-item label="TT penalty">
            {{ job.tpenalty }}
          </el-descriptions-item>
          <el-descriptions-item label="CC penalty">
            {{ job.cpenalty }}
          </el-descriptions-item>
          <el-descriptions-item label="Mean stability">
            {{ job.stability }}
          </el-descriptions-item>
          <el-descriptions-item label="Identity">
            {{ job.identity }}
          </el-descriptions-item>
          <el-descriptions-item label="Nt">
            {{ job.nt }}
          </el-descriptions-item>
          <el-descriptions-item label="Offset">
            {{ job.offset }}
          </el-descriptions-item>
          <el-descriptions-item label="email" span="3">
            {{ job.email}}
          </el-descriptions-item>
          <el-descriptions-item label="Result" span="3">
            <tr v-for="item in fileList" :key="item">
              <td class="text-justify text-body-2">{{ item }}</td>
              <td>
                <el-button icon="el-icon-download" size="small" type="primary" @click="downloadFile(item)"></el-button>
              </td>
            </tr>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios'

export default {
  name: "QueryJob.vue",
  data() {
    return {
      isShow: false,
      analysisId: null,
      job: {
        analysisID: null,
        job_status: null,
        result: null,
        rule: null,
        tpenalty: null,
        cpenalty: null,
        stability: null,
        identity: null,
        nt: null,
        offset: null,
        email: null
      },
      fileList: [],
      pariRules: [
        'All--default',
        'H1:TA-T,AT-G,CG-G,GC-T',
        'H2:TA-T,AT-G,CG-C,GC-T',
        'H3:TA-T,AT-G,CG-T,GC-T',
        'H4:TA-T,AT-G,CG-G,GC-C',
        'H5:TA-T,AT-G,CG-C,GC-C',
        'H6:TA-T,AT-G,CG-T,GC-C',
        'RH1:TA-T,AT-G,CG-G,GC-T',
        'RH2:TA-T,AT-G,CG-C,GC-T',
        'RH3:TA-T,AT-G,CG-A,GC-T',
        'RH4:TA-T,AT-G,CG-G,GC-C',
        'RH5:TA-T,AT-G,CG-C,GC-C',
        'RH6:TA-T,AT-G,CG-A,GC-C',
        'RH7:TA-A,AT-G,CG-G,GC-T',
        'RH8:TA-A,AT-G,CG-C,GC-T',
        'RH9:TA-A,AT-G,CG-A,GC-T',
        'RH10:TA-A,AT-G,CG-G,GC-C',
        'RH11:TA-A,AT-G,CG-C,GC-C',
        'RH12:TA-A,AT-G,CG-A,GC-C',
        'RH13:TA-C,AT-G,CG-G,GC-T',
        'RH14:TA-C,AT-G,CG-C,GC-T',
        'RH15:TA-C,AT-G,CG-A,GC-T',
        'RH16:TA-C,AT-G,CG-G,GC-C',
        'RH17:TA-C,AT-G,CG-C,GC-C',
        'RH18:TA-C,AT-G,CG-A,GC-C'
      ]
    }
  },
  methods: {
    getJob() {
      let that = this
      axios
          .get('/longtarget/jobs/' + that.analysisId)
          .then(function (res) {
            that.job = res.data
            if (that.job.job_status >= 1) {
              axios
                  .get(axios.defaults.baseURL + '/longtarget/filelist/' + that.analysisId)
                  .then(function (res) {
                    that.fileList = res.data.filelist
                  })
                  .catch(function (err) {
                    that.$message({
                      message: err,
                      type: "error"
                    })
                  })
            } else {
              that.fileList = []
            }
          })
          .catch(function (err) {
            that.$message({
              message: err,
              type: "error"
            })
          })
    },
    downloadFile(fileName) {
      let that = this
      axios
          .get('/longtarget/download/' + that.analysisId + '/' + fileName, {responseType: 'blob'})
          .then(function (res) {
            let blob = new Blob([res.data], {type: 'text/plain'})
            let objectUrl = URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = objectUrl
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
          })
          .catch(function (err) {
            that.$message({
              message: err,
              type: "error"
            })
          })
    },
    goBack() {
      this.$router.back()
    }
  },
}
</script>

<style scoped>

</style>

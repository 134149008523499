<template>
  <el-row type="flex" justify="center">
    <el-col :xl=16 :lg="18">
      <el-card shadow="always" class="box-card">
        <el-row>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item style="font-size: large">PopTradeOff</el-breadcrumb-item>
            <el-breadcrumb-item style="font-size: large">Introduction</el-breadcrumb-item>
          </el-breadcrumb>
        </el-row>
        <el-divider></el-divider>
        <el-row>
          <el-col :span="24" align="center">
            <el-image :src="img5" style="height: 800px"></el-image>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row type="flex">
          <el-col :span="24">
            <div style="font-size: large">
                Human populations have adapted to varied local environments and lifestyles during and after the
                out-of-Africa migrations. During the processes, many population-specific favored mutations have been
                generated and selected for. Increasing evidence indicates that a trade-off is also generated between
              adaptive evolution and disease susceptibilities (<i class="el-icon-document" style="color: #409EFF"></i><a href="https://www.nature.com/articles/s41576-020-00305-9">Benton et al., 2021</a>; <i class="el-icon-document" style="color: #409EFF"></i><a href="https://www.cell.com/cell/fulltext/S0092-8674(19)30115-1?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS0092867419301151%3Fshowall%3Dtrue"> Prohaska et al., 2019</a>). An example
                is the mutations in the HBB gene, which make some African populations resistant to malaria but also
                susceptible to sickle cell anemia. Especially favored mutations may cause disease susceptibility when
                lifestyles change. Unraveling the extent of the trade-offs and the breadth of their impact relies on the
                identification of population-specific favored mutations. Upon our recent work on the trade-off between
              favored mutations and disease susceptibility (<i class="el-icon-document" style="color: #409EFF"></i><a href="https://www.cell.com/cell-reports/fulltext/S2211-1247(22)01179-2?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS2211124722011792%3Fshowall%3Dtrue">Tang et al., 2022</a>), we have integrated multiple data
                resources to build the database PopTradeOff. This database includes favored and hitchhiking neutral
                mutations in 17 human populations, related GWAS sites, drug response-related genes and variants, and
                information on gene function and expression. These data allow researchers to examine mutations, genes,
                diseases, drugs, and human populations comprehensively. The combination of a search engine and a genome
                browser allows the powerful search of varied information and presents related information coherently.
              </div>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "PopFMIntro",
  data() {
    return {
      img5: require('../assets/popfm_pic5.png'),
    }
  },
}
</script>

<style scoped>
.box-card {
  min-width: 100%;
  height: 100%;
  margin-right: 20px;
}
</style>

<template>
  <el-row type="flex" justify="center">
    <el-col :lg="18" :xl="12">
      <el-card shadow="always">
        <el-row>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item style="font-size: large">PopTradeOff</el-breadcrumb-item>
            <el-breadcrumb-item style="font-size: large">Search</el-breadcrumb-item>
          </el-breadcrumb>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter=20>
          <el-col :span="12">
            <h3>1. Specify a mutation type</h3>
            <el-select v-model="mutation_type" size="small">
              <el-option label="Favored-default" value="1"></el-option>
              <el-option label="Hitchhiking" value="2"></el-option>
            </el-select>
            <h3>2. Specify a population</h3>
            <el-select v-model="tar_pop" size="small">
              <el-option-group
                  v-for="group in popItems"
                  :key="group.label"
                  :label="group.label">
                <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-option-group>
            </el-select>
            <h3>3. Specify a range of derived allele frequency</h3>
            <el-input-number v-model="daf1" size="small" :precision="1" :step="0.1" :max="daf2"></el-input-number>
            -
            <el-input-number v-model="daf2" size="small" :precision="1" :step="0.1" :max="1"></el-input-number>
            <h3>4. Specify a disease or trait</h3>
            <p>4.1 Select a GWAS disease/trait</p>
            <el-select v-model="gwas_disease" clearable size="small">
              <el-option v-for="item in gwasItems" :key="item.value" :label="item.text"
                         :value="item.value"></el-option>
            </el-select>
            <p>4.2 Select a KEGG disease</p>
            <el-select v-model="kegg_disease" clearable size="small">
              <el-option v-for="item in keggItems" :key="item.value" :label="item.text"
                         :value="item.value"></el-option>
            </el-select>
            <p>4.3 Input a KEGG disease ID or a keyword</p>
            <el-row>
              <el-col :span=12>
                <el-input v-model="disease_id" size="small"
                          placeholder="for example, ds:H00837, parkinson" clearable></el-input>
              </el-col>
            </el-row>
            <h3>5. Search drug-related loci</h3>
            <p>5.1 Select a data resource</p>
            <el-select v-model="drug_select" clearable size="small">
              <el-option label="Search drug response-related loci" value="1"></el-option>
              <el-option label="Search drug action-related loci" value="2"></el-option>
            </el-select>
            <el-popover placement="bottom" trigger="hover" width="300" v-show="drug_select=='1'">
              <div style="word-break: normal">Drug response-related loci are the loci where patients with
                different genotypes respond to drugs in different ways, for example whether they have
                adverse reactions. The data were currently collected from PharmGKB, NHGRI-EBI GWAS
                catalog.
              </div>
              <i class="el-icon-info" style="color: #409EFF" slot="reference"></i>
            </el-popover>
            <el-popover placement="bottom" trigger="hover" width="300" v-show="drug_select=='2'">
              <div style="word-break: normal">Drug action-related loci are the loci influencing the
                targeting, metabolism and movement in human body of a drug. The data were currently
                collected from DrugBank.
              </div>
              <i class="el-icon-info" style="color: #409EFF" slot="reference"></i>
            </el-popover>
            <p>5.2 Input a drug name</p>
            <el-row>
              <el-col :span=12>
                <el-input v-model="drug_info" size="small" clearable
                          placeholder="for example, copper"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <h3>6. Specify a regulatory site</h3>
            <p>6.1 Select a kind of loci</p>
            <el-select v-model="regulatory_type" clearable size="small">
              <el-option v-for="item in regulatoryItems" :key="item.value" :label="item.text"
                         :value="item.value"></el-option>
            </el-select>
            <p>6.2 Select a tissue/cell type</p>
            <el-select v-model="cell_type" filterable clearable size="small">
              <el-option v-for="item in celltypeItems" :key="item.value" :label="item.text"
                         :value="item.value"></el-option>
            </el-select>
            <h3>7. Specify a gene/gene region/gene set</h3>
            <p>7.1 Select a gene region of interest</p>
            <el-select v-model="gene_region" clearable size="small">
              <el-option v-for="item in generegionItems" :key="item.value" :label="item.text"
                         :value="item.value"></el-option>
            </el-select>
            <p>7.2 Select a gene biotype</p>
            <el-select v-model="gene_biotype" clearable size="small">
              <el-option v-for="item in genebiotypeItems" :key="item.value" :label="item.text"
                         :value="item.value"></el-option>
            </el-select>
            <p>7.3 Input a KEGG pathway ID, GO term ID, or gene name</p>
            <el-row>
              <el-col :span=12>
                <el-input v-model="gene_name" size="small"
                          placeholder="for example, path:hsa05022, GO:1903955 or UBE2J2"
                          clearable></el-input>
              </el-col>
            </el-row>
            <h3>8. Specify a genomic region or a rsid</h3>
            <el-row>
              <el-col :span="6">
                <el-select size="small" v-model="chr" placeholder="Chromosome">
                  <el-option v-for="item in chrItems" :key="item.value" :label="item.text"
                             :value="item.value"></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" :offset="1">
                <el-input size="small" v-model="posFrom" placeholder="From"
                          onkeyup="value=value.replace(/[^\d]/g,0)"></el-input>
              </el-col>
              <el-col :span=8 :offset="1">
                <el-input size="small" v-model="posTo" placeholder="To"
                          onkeyup="value=value.replace(/[^\d]/g,0)"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span=12>
                <el-input v-model="rsid" size="small" clearable onkeyup="value=value.replace(/[^\d]/g,0)">
                  <template slot="prepend">rs</template>
                </el-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-button type="primary" icon="el-icon-search" @click="submit">Search</el-button>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "PopFMSearch",
  data() {
    return {
      msg: 'PopFMSearch',
      mutation_type: '1',
      posFrom: null,
      posTo: null,
      tar_pop: '0',
      gene_region: '',
      gene_biotype: '',
      gene_name: '',
      rsid: '',
      regulatory_type: '',
      cell_type: 'All',
      gwas_disease: '',
      kegg_disease: '',
      disease_id: '',
      drug_select: '',
      drug_info: '',
      chr: 0,
      daf1: 0.0,
      daf2: 1.0,
      popItems: [
        {
          label: 'All',
          options: [
            {label: 'All--default', value: '0'}
          ]
        },
        {
          label: 'European',
          options: [
            {label: 'CEU', value: 'CEU'},
            {label: 'FIN', value: 'FIN'},
            {label: 'GBR', value: 'GBR'},
            {label: 'IBS', value: 'IBS'},
            {label: 'TSI', value: 'TSI'},
          ]
        },
        {
          label: 'Easian',
          options: [
            {label: 'CHB', value: 'CHB'},
            {label: 'CDX', value: 'CDX'},
            {label: 'CHS', value: 'CHS'},
            {label: 'JPT', value: 'JPT'},
            {label: 'KHV', value: 'KHV'},
          ]
        },
        {
          label: 'African',
          options: [
            {label: 'YRI', value: 'YRI'},
            {label: 'ACB', value: 'ACB'},
            {label: 'ASW', value: 'ASW'},
            {label: 'ESN', value: 'ESN'},
            {label: 'GWD', value: 'GWD'},
            {label: 'LWK', value: 'LWK'},
            {label: 'MSL', value: 'MSL'},
          ]
        },
      ],
      generegionItems: [
        {text: 'Promoter', value: 'promoter'},
        {text: 'Exon', value: 'exon'},
        {text: 'Intron', value: 'intron'},
      ],
      genebiotypeItems: [
        {text: 'protein-coding', value: 'protein'},
        {text: 'lincRNA', value: 'lincrna'},
        {text: 'antisense', value: 'antisense'}
      ],
      regulatoryItems: [
        {text: 'TFBS', value: 'tfbs'},
        {text: 'enhancer', value: 'enhancer'},
        {text: 'eQTL', value: 'eqtl'},
        {text: 'mQTL', value: 'mqtl'},
        {text: 'hQTL', value: 'hqtl'},
        {text: 'sQTL', value: 'sqtl'},
      ],
      celltypeItems: [
        {text: 'All', value: 'All'},
        {text: 'Adipose', value: 'Adipose'},
        {text: 'Adrenal_Gland', value: 'Adrenal_Gland'},
        {text: 'Artery', value: 'Artery'},
        {text: 'Bladder', value: 'Bladder'},
        {text: 'Blood', value: 'Blood'},
        {text: 'Bone', value: 'Bone'},
        {text: 'Brain', value: 'Brain'},
        {text: 'Breast', value: 'Breast'},
        {text: 'Cancer', value: 'Cancer'},
        {text: 'Cervix', value: 'Cervix'},
        {text: 'Dendritic_cells', value: 'Dendritic_cells'},
        {text: 'Digestive', value: 'Digestive'},
        {text: 'ES-deriv', value: 'ES-deriv'},
        {text: 'ESC', value: 'ESC'},
        {text: 'Endocrine', value: 'Endocrine'},
        {text: 'Endothelial', value: 'Endothelial'},
        {text: 'Epithelial', value: 'Epithelial'},
        {text: 'Epithelium', value: 'Epithelium'},
        {text: 'Esophagus', value: 'Esophagus'},
        {text: 'Eye', value: 'Eye'},
        {text: 'Fibroblast', value: 'Fibroblast'},
        {text: 'Gallbladder', value: 'Gallbladder'},
        {text: 'Heart', value: 'Heart'},
        {text: 'Intestine', value: 'Intestine'},
        {text: 'Kidney', value: 'Kidney'},
        {text: 'Liver', value: 'Liver'},
        {text: 'Lung', value: 'Lung'},
        {text: 'Lymphoblastoid', value: 'Lymphoblastoid'},
        {text: 'Lymphocyte', value: 'Lymphocyte'},
        {text: 'Mesench', value: 'Mesench'},
        {text: 'Mouth', value: 'Mouth'},
        {text: 'Muscle', value: 'Muscle'},
        {text: 'Myosat', value: 'Myosat'},
        {text: 'Neurosph', value: 'Neurosph'},
        {text: 'Ovary', value: 'Ovary'},
        {text: 'PNS', value: 'PNS'},
        {text: 'Pancreas', value: 'Pancreas'},
        {text: 'Placenta', value: 'Placenta'},
        {text: 'Prostate', value: 'Prostate'},
        {text: 'Reproductive', value: 'Reproductive'},
        {text: 'Skin', value: 'Skin'},
        {text: 'Spleen', value: 'Spleen'},
        {text: 'Stomach', value: 'Stomach'},
        {text: 'Stromal', value: 'Stromal'},
        {text: 'Testis', value: 'Testis'},
        {text: 'Thymus', value: 'Thymus'},
        {text: 'Thyroid_Gland', value: 'Thyroid_Gland'},
        {text: 'Urinary', value: 'Urinary'},
        {text: 'Uterus', value: 'Uterus'},
        {text: 'Vagina', value: 'Vagina'},
        {text: 'iPSC', value: 'iPSC'}
      ],
      gwasItems: [
        {text: 'All', value: '0'},
        {text: 'Biological process', value: 'A'},
        {text: 'Body measurement', value: 'B'},
        {text: 'Cancer', value: 'C'},
        {text: 'Cardiovascular disease', value: 'D'},
        {text: 'Cardiovascular measurement', value: 'E'},
        {text: 'Digestive system disorder', value: 'F'},
        {text: 'Hematological measurement', value: 'G'},
        {text: 'Immune system disorder', value: 'H'},
        {text: 'Inflammatory measurement', value: 'I'},
        {text: 'Lipid or lipoprotein measurement', value: 'J'},
        {text: 'Liver enzyme measurement', value: 'K'},
        {text: 'Metabolic disorder', value: 'L'},
        {text: 'Neurological disorder', value: 'M'},
        {text: 'Response to drug', value: 'N'},
        {text: 'Other diseases/traits', value: 'O'},
      ],
      keggItems: [
        {text: 'All', value: '0'},
        {text: 'Cancers', value: 'A'},
        {text: 'Cardiovascular diseases', value: 'B'},
        {text: 'Congenital disorders of metabolism', value: 'C'},
        {text: 'Congenital malformations', value: 'D'},
        {text: 'Digestive system diseases', value: 'E'},
        {text: 'Endocrine and metabolic diseases', value: 'F'},
        {text: 'Immune system diseases', value: 'G'},
        {text: 'Musculoskeletal diseases', value: 'H'},
        {text: 'Nervous system diseases', value: 'I'},
        {text: 'Other congenital disorders', value: 'J'},
        {text: 'Other diseases', value: 'K'},
        {text: 'Reproductive system diseases', value: 'L'},
        {text: 'Respiratory diseases', value: 'M'},
        {text: 'Skin diseases', value: 'N'},
        {text: 'Urinary system diseases', value: 'O'},
      ],
      chrItems: [
        {text: 'Chr All', value: 0},
        {text: 'Chr 1', value: 1},
        {text: 'Chr 2', value: 2},
        {text: 'Chr 3', value: 3},
        {text: 'Chr 4', value: 4},
        {text: 'Chr 5', value: 5},
        {text: 'Chr 6', value: 6},
        {text: 'Chr 7', value: 7},
        {text: 'Chr 8', value: 8},
        {text: 'Chr 9', value: 9},
        {text: 'Chr 10', value: 10},
        {text: 'Chr 11', value: 11},
        {text: 'Chr 12', value: 12},
        {text: 'Chr 13', value: 13},
        {text: 'Chr 14', value: 14},
        {text: 'Chr 15', value: 15},
        {text: 'Chr 16', value: 16},
        {text: 'Chr 17', value: 17},
        {text: 'Chr 18', value: 18},
        {text: 'Chr 19', value: 19},
        {text: 'Chr 20', value: 20},
        {text: 'Chr 21', value: 21},
        {text: 'Chr 22', value: 22},
        {text: 'Chr X', value: 23}
      ],
    }
  },
  methods: {
    submit() {
      this.$router.push({
        path: '/PopFMList',
        query: {
          mutation_type: this.mutation_type,
          tar_pop: this.tar_pop,
          gene_biotype: this.gene_biotype,
          gene_region: this.gene_region,
          gene_name: this.gene_name,
          regulatory_type: this.regulatory_type,
          cell_type: this.cell_type,
          gwas_disease: this.gwas_disease,
          kegg_disease: this.kegg_disease,
          disease_id: this.disease_id,
          rsid: this.rsid,
          drug_select: this.drug_select,
          drug_info: this.drug_info,
          chr: this.chr.toString(),
          start: this.posFrom == null ? 0 : this.posFrom.toString(),
          end: this.posTo == null ? 0 : this.posTo.toString(),
          daf1: this.daf1.toString(),
          daf2: this.daf2.toString()
        }
      })
    }
  }
}
</script>

<style scoped>
.box-card {
  min-width: 100%;
  height: 100%;
  margin-right: 20px;
}

.el-row {
  margin-bottom: 20px;
}
</style>

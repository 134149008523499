<template>
  <el-row type="flex" justify="center">
    <el-col :lg="24" :xl="20">
      <el-row type="flex" justify="center">
        <el-card shadow="always" class="box-card">
          <el-table v-loading="loading" :data="popFMs.slice((currentPage-1)*pageSize,currentPage*pageSize)"
                    style="width: 100%" align="center" :cell-style="{'text-align':'center'}"
                    :header-cell-style="{'text-align':'center'}" @row-click="showDetail"
                    :default-sort="{prop: 'chr', order: 'ascending'}" border>
            <el-table-column label="Position" min-width="6%" :show-overflow-tooltip="true">
              <template v-slot="scope">
                <div slot="content">Chr{{ scope.row.chr}}:{{scope.row.pos}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="rsid" label="Rsid" min-width="6%" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="daf" label="DAF" min-width="6%">
                <template slot-scope="{}" slot="header">
                    <span>DAF</span>
                    <el-tooltip class="item" placement="bottom" effect="light">
                        <i class="el-icon-info" style="color:#409eff"></i>
                        <div slot="content">Derived Allele Frequency</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="dfs" label="DFScore" min-width="6%"></el-table-column>
            <el-table-column prop="ld" label="LD" min-width="6%"></el-table-column>
            <el-table-column prop="tar_pop" label="Target_Pop" min-width="8%"></el-table-column>
            <el-table-column prop="genename" label="Gene" min-width="15%"></el-table-column>
            <el-table-column label="KEGG disease" min-width="15%">
              <template v-slot="scope">
                <div slot="content" v-show="scope.row.keggdisease_category!='0'" align="left">
                  <div v-html="getKeggInfo(scope.row.keggdisease_category)"></div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="gwas_category" label="GWAS Category disease" min-width="15%">
              <template v-slot="scope">
                <div slot="content" v-show="scope.row.gwas_category!='0'" align="left">
                  <div v-html="getGawsInfo(scope.row.gwas_category)"></div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="PharmGKB" min-width="8%">
              <template v-slot="scope">
                <el-tag type="success" v-show="scope.row.drugresponse!=''"><i class="el-icon-check"></i></el-tag>
              </template>
            </el-table-column>
            <el-table-column label="DrugBank" min-width="8%">
              <template v-slot="scope">
                <el-tag type="success" v-show="scope.row.drugaction!=''"><i class="el-icon-check"></i></el-tag>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="popFMs.length">
          </el-pagination>
        </el-card>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios'

export default {
  name: "PopFMList",
  data() {
    return {
      popFMs: [],
      handleList: [],
      currentPage: 1,
      pageSize: 10,
      loading: true,
      gwasItems: [
        'Biological process',
        'Body measurement',
        'Cancer',
        'Cardiovascular disease',
        'Cardiovascular measurement',
        'Digestive system disorder',
        'Hematological measurement',
        'Immune system disorder',
        'Inflammatory measurement',
        'Lipid or lipoprotein measurement',
        'Liver enzyme measurement',
        'Metabolic disorder',
        'Neurological disorder',
        'Response to drug',
        'Other diseases/traits',
      ],
      keggItems: [
        'Cancers',
        'Cardiovascular diseases',
        'Congenital disorders of metabolism',
        'Congenital malformations',
        'Digestive system diseases',
        'Endocrine and metabolic diseases',
        'Immune system diseases',
        'Musculoskeletal diseases',
        'Nervous system diseases',
        'Other congenital disorders',
        'Other diseases',
        'Reproductive system diseases',
        'Respiratory diseases',
        'Skin diseases',
        'Urinary system diseases',
      ],
      drugItems: ['Target', 'Enzyme', 'Carrier', 'Transporter'],
    }
  },
  mounted() {
    let that = this
    let urlstr = ''
    if (that.$route.query.mutation_type == '1') {
      urlstr = '/popfm/favsnp/list/'
    } else {
      urlstr = '/popfm/hitchsnp/list/'
    }
    axios
        .get(urlstr, {
          params: that.$route.query
        })
        .then(function (res) {
          that.popFMs = res.data
          that.loading = false
        })
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    showDetail(value) {
      this.$router.push({
        path: "/PopFMShow",
        query: {chr: value.chr, pos: value.pos, pop: value.tar_pop, sup_pop: value.sup_pop}
      })
    },
    getKeggInfo(val) {
      let res = ''
      for (let ch of val) {
        res += this.keggItems[ch.charCodeAt() - 'A'.charCodeAt()] + "<br/>"
      }
      return res
    },
    getGawsInfo(val) {
      let res = ''
      for (let ch of val) {
        res += this.gwasItems[ch.charCodeAt() - 'A'.charCodeAt()] + "<br/>"
      }
      return res
    },
    getDrugresponseInfo(val) {
      let res = ''
      if (val.length == 0) {
        return res
      }
      for (let ch of val) {
        res += this.drugItems[ch.charCodeAt() - 'A'.charCodeAt()] + "<br/>"
      }
      return res
    },
    getGeneInfo(val) {
      let res = ''
      if (val.genename != 'N') {
        res += "Gene: " + val.genename + "<br/>"
      }
      if (val.protein_promoter || val.protein_exon || val.protein_intron) {
        res += 'Protein:'
        res += ((val.protein_promoter) ? ' promoter' : '')
        res += (val.protein_exon) ? ' exon' : ''
        res += ((val.protein_intron) ? ' intron' : '')
        res += '<br/>'
      }
      if (val.lincrna_promoter || val.lincrna_exon || val.lincrna_intron) {
        res += "lincRNA:"
        res += (val.lincrna_promoter) ? " promoter" : ''
        res += (val.lincrna_exon) ? " exon" : ''
        res += (val.lincrna_intron) ? " intron" : ''
        res += "<br/>"
      }
      if (val.antisense_promoter || val.antisense_exon || val.antisense_intron) {
        res += "Antisense:"
        res += (val.antisense_promoter) ? " promoter" : ''
        res += (val.antisense_exon) ? " exon" : ''
        res += (val.antisense_intron) ? " intron" : ''
        res += "<br/>"
      }
      return res
    },
    renderHeader(h, {column}) {
      return h('span', {}, [
        h('span', {}, column.label.split('/')[0]),
        h('br'),
        h('span', {}, column.label.split('/')[1])
      ]);
    }
  }
}

</script>

<style scoped>
.box-card {
  min-width: 100%;
  height: 100%;
}
</style>

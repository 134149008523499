<template>
  <el-row type="flex" justify="center">
    <el-col :span="16">
      <el-card shadow="always" class="box-card">
        <el-table v-loading="loading" :data="geneList.slice((currentPage-1)*pageSize,currentPage*pageSize)"
                  style="width: 100%" align="center" :cell-style="{'text-align':'center'}"
                  :header-cell-style="{'text-align':'center'}" @row-click="showDetail"
                  :default-sort="{prop: 'chr', order: 'ascending'}">
          <el-table-column prop="id" label="smu_id" min-width="10%"></el-table-column>
          <el-table-column prop="species" label="species" min-width="10%">
            <template slot-scope="scope">
              <el-tag size="small" type="warning" v-show="scope.row.species!=null">{{scope.row.species}}</el-tag>
              <el-tag size="small" type="success" v-show="scope.row.species==null">Human</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="gtf_gene_id" label="HGNC gene symbol" min-width="20%"></el-table-column>
          <el-table-column prop="alias" label="gene_name(alias_name)" min-width="20%"></el-table-column>
          <el-table-column prop="seqname" label="Chr" min-width="10%"></el-table-column>
          <el-table-column prop="strand" label="strand" min-width="10%"></el-table-column>
          <el-table-column prop="start" label="start" min-width="10%"></el-table-column>
          <el-table-column prop="end" label="end" min-width="10%"></el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="geneList.length">
        </el-pagination>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios'

export default {
  name: "LongManList",
  data() {
    return {
      geneList: [],
      currentPage: 1,
      pageSize: 20,
      loading: true
    }
  },
  mounted() {
    let that = this
    if (that.$route.query.speciesid == '1') {
      axios
          .get('/longman/genelist/',
              {params: that.$route.query
          })
          .then(function (res) {
            that.geneList = res.data
            that.loading = false
          })
    }
    else {
      axios
          .get('/longman/homogenelist/', {
            params: that.$route.query
          })
          .then(function (res) {
            that.geneList = res.data
            that.loading = false
          })
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    showDetail(value) {
      if (value.species_id == null) {
        value.species_id = 1
        value.species = 'Human'
      }
      this.$router.push({path: "/LongManShow", query: {value}})
    }
  }
}
</script>

<style scoped>
.box-card {
  min-width: 100%;
  height: 100%;
  margin-right: 20px;
}
</style>

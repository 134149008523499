<template>
  <el-row type="flex" justify="center">
    <el-col :lg="16" :xl="12">
      <el-card shadow="always" class="box-card">
        <div slot="header" class="clearfix" align="center">
          <span style="font-size: xx-large"><i class="el-icon-s-home" style="font-size: xx-large; color: blue"></i>Welcome to Gaemons!</span>
        </div>
        <p class="text" style="font-size: x-large">This website <i class="el-icon-link" style="color: blue"></i><a href="http://www.gaemons.net">www.gaemons.net</a>  hosts online services and databases developed by team of <i class="el-icon-message" style="color: blue"></i><a href=mailto:zhuhao@smu.edu.cn>Dr Zhu</a> and <i class="el-icon-message" style="color: blue"></i><a href=mailto:zhangh@smu.edu.cn>Dr Zhang</a> at Southern Medical University, Guangzhou, China.</p>
        <p class="text" style="font-size: x-large">The pipelines for cross-species and genome-wide lncRNA/DNA binding analysis (<i class="el-icon-document" style="color: blue"></i><a href="https://www.nature.com/articles/s41596-018-0115-5" target="_blank"> Lin et al. Nature Protocols</a>, 2019, 14(3):795-818) have been previously at <i class="el-icon-link" style="color: blue"></i><a href="http://lncRNA.smu.edu.cn" target="_blank">http://lncrna.smu.edu.cn</a> . We are transferring the front-end of the application here. Some functions (the LongMan database) have been available, some (the LongTarget tool) needs more time. We apologize for this.</p>
        <p class="text" style="font-size: x-large">Recently, by benchmarking 18 related algorithms and analyzing multiple cell types, we have applied causal discovery to single-cell data.  We have developed a website called CausalCell.  It was initially at <i class="el-icon-link" style="color: blue"></i><a href="http://lncrna.smu.edu.cn" target="_blank">http://lncrna.smu.edu.cn</a> and has also been implemented here.</p>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      value1: true,
    }
  }
}
</script>

<style scoped>
.text {
  font-size: 14px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
.box-card {
  min-width: 100%;
  height: 100%;
  margin-right: 20px;
}
</style>
